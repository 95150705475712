<script>
console.log("HEY YOU FOUND ME :)")
</script>

<template> 
<h1>EDUCATION</h1>

<h2>M.S. MECHANICAL ENGINEERING</h2>
<h3>Noise And Vibration Control</h3>
<h4>MAY 2021</h4>
<h3>L'Aquila, Italy</h3>
<h4>SEPTEMBER 2019 - JANUARY 2020</h4>
<p>Offered a scholarship to study abroad in Italy by Dr. Chalhoub, head of the Mechanical Engineering department.
I was to be the first student to pilot this new study abroad program and give feedback on all the pain points to help smooth the process for future students.
Studied Advanced Control Systems and Python. 
 I'm Extremely grateful for the opportunity </p>


<h2>BACHELORS DEGREE</h2>
<h4>December 2018</h4>

<h3>Sinusoidal Engine</h3>
<h4>Advanced CAD Project (ME 5580)</h4>
<p>Designed a conceptual novel design of an engine combining the sinusoidal and duke engines using NX.
Conducted CAE (FEA, CFD, and thermal) analysis on the parts in addition to simulating it in NX.</p>

<div class="diagrams"> 
    <img src="images/first.jpeg" alt="First Image">
</div>


<h3>Portable Water Filtration System</h3>
<h4>Senior Design Project (ME 4500)</h4>
<p>This project was intended to tackle the issue of water scarcity in underdeveloped regions of the world. We developed a replicable process to develop a cheap and portable water filtration system that can be taken from village to village easily or even transport the water a reasonable distance.
the system uses a three stage filtration process that could clean and sanitize natural water using a dual stage particulate filter, carbon filter, and a UV filter..
We cut open an electric pump which was attached to the stand in such a way to pump the water only when the bicycle was in the pumping configuration. then attached it to the filters which were placed in a balanced configuration when transporting water. An added option was the UV filter which we were unsure would be feasible in the target communities yet if possible was highly recommended. For the UV filter two Dynamos and a small battery, to ensure consistent power delivery for the filter, were added.</p>

<div class="diagrams"> 
    <img src="images/second.jpeg" alt="Second Image">
</div>

</template> 

<style scoped>
.diagrams {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.diagrams img {
  max-width: 100%;
  max-height: 900px;
  object-fit: contain;
}

@media (max-width: 767px) {
    body {
        padding-left: 3vw;
        padding-right: 3vw; 
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 15px;
    }
}
</style>