<script>
console.log("HEY YOU FOUND ME :)")
</script>

<template> 
<h1>WORK EXPERIENCE</h1>
<div class="image-container"> 
    <img src="images/bosch.png">
</div>
<h2>REGIONAL SAFETY MANAGER</h2>
<h3>MAY 2022 – PRESENT</h3>
<h3>BOSCH</h3>
<p>Supervisor: <a href="https://www.linkedin.com/in/tchaudhari/" target="_blank" rel="noopener noreferrer">Tushar Chaudhari</a></p>
<h4>Responsible for overall Function Safety Management in context of Engineering of Bosch Automotive Steering Systems.</h4>
<p>Ensure Safety and security of Bosch electric steering systems by adhering to ISO26262
Create and update the hazard and risk analyses, safety plan, functional and technical safety concept
Support the definition/specification of safety requirements for hardware and software architectures
Lead support Safety Assessment Reviews held by safety assessor
Responsible for most GM projects in North America</p>


<div class="image-container"> 
    <img src="images/invensity.jpg">
</div>
<h2>CONSULTANT</h2>
<h3>JULY 2021 – MAY 2022</h3>
<h3>INVENSITY</h3>
<p>Supervisor: <a href="https://www.linkedin.com/in/tristan-eggenberger-4b1311123/" target="_blank" rel="noopener noreferrer">Tristan Eggenberger</a></p>
<h4>Ensure client satisfaction by performing the assigned tasks as well as correcting and resolving any issues that may crop up.</h4>
<h3>REQUIREMENTS ENGINEERING</h3>
<p>Analysis and assigning incoming stakeholder requirements
Creating and maintaining traceability between system architecture and system requirement
Decomposition of requirements</p>
<h3>TECHNICAL PROJECT MANAGEMENT</h3>
<p>Leading engineers from multiple engineering disciplines to develop & deliver automotive driveline mechatronics systems.
Working across multiple disciplines in System V development process
Developing driveline products incorporating automotive industry standards & processes including ASPICE, ISO26262.
Utilizing project planning, scope break down & allocation, issue management & resolution, with Agile principles utilizing IBM RTC</p>


<div class="image-container"> 
    <img src="images/innovatrium.jpg">
</div>
<h2>INNOVATION CONSULTANT</h2>
<h3>MAY 2021 – FEBRUARY 2022</h3>
<h3>INNOVATRIUM</h3>
<p>Supervisors: <a href="https://www.linkedin.com/in/staney-degraff-31870718/" target="_blank" rel="noopener noreferrer">Dr. Staney DeGraff</a>, <a href="https://www.linkedin.com/in/degraffjeff/" target="_blank" rel="noopener noreferrer">Dr. Jeff GeGraff</a></p>
<h4>Working with Air National Guard and Air Force members to hold in-person coachings for AIMHI and Project Mercury as well as launching Project DAWG</h4>
<p>AIMHI and Project Mercury aim to develop Innovation hubs within the Air National Guard and Air Force respectively. 
Project DAWG (Developing Airmen With Games) is a student game development competition that's currently in progress and due to be completed by the end of the end of summer.</p>


<div class="image-container"> 
    <img src="images/waymo.png">
</div>
<h2>FLEET SYSTEMS OPERATOR</h2>
<h3>JANUARY 2021 – JULY 2021</h3>
<h3>WAYMO</h3>
<p>Supervisor: <a href="https://www.linkedin.com/in/mazen-s-893087158/" target="_blank" rel="noopener noreferrer">Mazen Sarsour</a></p>
<h4>Assess and analyze real world and simulated situations to support vehicle operations in the field at Waymo via Genesis10.</h4>
<p>Analyze various situations the car encounters and provide feedback to the engineering team to allow for software improvement
Ensure smooth day to day operations of a fleet of autonomous vehicles.
Detect, communicate, and escalate potential issues to appropriate parties.
Work with engineers to enhance the software of autonomous vehicles.</p>


<div class="image-container"> 
    <img src="images/wayne.jpg">
</div>
<h2>RESEARCH ASSISTANT</h2>
<h3>MAY 2019 - SEPTEMBER 2019</h3>
<h3>Wayne State University</h3>
<p>Supervisor: <a href="https://engineering.wayne.edu/profile/bb1599" target="_blank" rel="noopener noreferrer">Dr. Marcis Jansons</a></p>
<h4>Conducting engine research on behalf TARDEC and Toyota. Then, converted the diesel research engine to gasoline.</h4>
<p>Conducted calibration testing for all components using LabVIEW and MATLAB.
Studied the effects of water injection in the combustion chamber of diesel engines by conducting highspeed imaging using LabVIEW and a highspeed thermal camera.
Converted an AVL optical engine (15 of in the U.S.) from a CI diesel to the only SI gasoline engine of its kind. For which I studied engine design and mated it to a one-of-a-kind cylinder head.
Designed new components and assemblies using Fusion360 and UG/NX.</p>


<div class="image-container" id="two-images"> 
    <img src="images/zf.png">
    <img src="images/trw.jpg">
</div>
<h2>PRODUCT ENGINEERING CO-OP</h2>
<h3>SEPTEMBER 2018 – DECEMBER 2018</h3>
<h3>ZF-TRW</h3>
<p>Supervisor: <a href="https://www.linkedin.com/in/markmieloch/" target="_blank" rel="noopener noreferrer">Mark Mieloch</a></p>
<h4>Worked in the Foundation Braking department on FCA vehicles under Mr. Mark Mieloch</h4>
<p>Assisted the FCA team on multiple projects, mainly the Dodge Ram HD and the Jeep Wrangler.
Analyzed test reports and created internal and external DVP&Rs.
Shared ownership of the Wrangler project with one other engineer, where I maintained full ownership of the front brakes testing.
Conducted frequency spectrum analysis testing on drum brakes by collecting data to be analyzed using Fast Fourier Transform (FFT)
Was given full ownership of a project which I developed a test plan for</p>

<div class="image-container"> 
   <img src="images/xenith.jpg">
</div>
<h2>QUALITY ENGINEERING INTERN</h2>
<h3>JUNE 2018 – AUGUST 2018</h3>
<h3>XENITH</h3>
<p>Supervisor: <a href="https://www.linkedin.com/in/david-marten-01334615/" target="_blank" rel="noopener noreferrer">David Marten</a></p>
<p>I worked along side Mohammad Ali Al-Ameen and David Marten to form the quality engineering team.
 My duties included handling warranty claims, Inspecting incoming shipments, quality control of outgoing shipments, in addition to assisting assembly line workers wherever needed.
While there I developed a new warranty system to generate data that could be used to inform design updates or upcoming products. In addition, I conducted a study to address assembly inconsistencies which highlighted a tolerance issue.</p>


<div class="image-container"> 
    <img src="images/innovatrium.jpg">
</div>
<h2>INNOVATION FELLOW</h2>
<h3>NOVEMBER 2017 - JULY 2018</h3>
<h3>INNOVATRIUM</h3>
<p>Supervisors: Dr. Ethan Eagle, <a href="https://www.linkedin.com/in/jim-fish/" target="_blank" rel="noopener noreferrer">Dr. Jim Fish</a>, <a href="https://www.linkedin.com/in/staney-degraff-31870718/" target="_blank" rel="noopener noreferrer">Dr. Staney DeGraff</a></p>
<p>Assisted Dr. Ethan Eagle and Dr. Jim Fish on projects with Bosch then ITC. 
Coordinated teams and guided team members on starting new innovative projects over a three month period until they presented their funding proposals.
Conducted market research and analysis to assist the teams in maintaining their deadlines. 
Monitored team progression and made suggestions to guide their progress.</p>
</template> 

<style>
body {
  padding-left: 25vw;
  padding-right: 25vw;
}

.image-container {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  margin-bottom: 20px; 
}

.image-container img {
  max-width: 100%; 
  max-height: 300px; 
  object-fit: contain;
}

@media (max-width: 767px) {
    body {
        padding-left: 5vw; /* this for some reason when changed from 25 to 5 is messing up the nav bar*/
        padding-right: 5vw; /* this for some reason when changed from 25 to 5 is messing up the nav bar*/
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 15px;
    }
    
    #two-images {
        display: flex;
        justify-content: center; /* Center items horizontally */
        align-items: center; /* Center items vertically */
        flex-wrap: wrap; /* Allow items to wrap to the next line on small screens */
        margin-bottom: 20px; /* Add some space below the container */
    }
}
</style>
